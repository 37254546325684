@font-face {
  font-family: 'lantin';
  src: url(./fonts/蘭亭.ttf);
}
@font-face {
  font-family: 'impact';
  src: url(./fonts/Impact.ttf);
  unicode-range: U+00-024F;
}
@font-face {
  font-family: 'CSong3HK';
  src: url(./fonts/CSong3HK-Medium.otf);
  unicode-range: U+4E00-9FFF;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html,
body {
  font-size: 12px;
}
body {
  /* font-family: 'impact', 'lantin', 'Tsukushi B Round Gothic', 'jf-openhuninn',
    '標楷體'; */
}

/* 隱藏 IE 的 scrollbar */
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
