.Embed  .HoverCard {
  display: none !important;
}
.Embed  .Feedback {
  display: none !important;
}
.Embed  .SocialProof {
  display: none !important;
}
.Embed  .Footer {
  display: none !important;
}
